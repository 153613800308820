<template>
	<div class="commodityList">
		<frame :top1="top1" :top2="top2">
			<tabbar :tabbar="tabbar" @cuts='cut'></tabbar>
			<search @search='search' :set='set' :pageSize='pageSize'></search>
			<lists :titles='titles' :flag='flag' :contentC='datas'  @add='refresh()' @del='refresh()'></lists>
			<!-- <page :num='num'></page> -->
			<el-pagination
			  @size-change="handleSizeChange"
			  @current-change="handleCurrentChange"
			  :current-page="currentPage4"
			  :page-sizes="[10,15]"
			  :page-size="pageSize"
			  layout="total, sizes, prev, pager, next, jumper"
			  :total="titlePage"
			>
			</el-pagination>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import lists from './componList/Table.vue';
	import tabbar from './componList/TabbarList.vue';
	import search from './componList/Search.vue';
	import page from './componList/Page.vue';
	export default {
		name: 'commodityList',
		data() {
			return {
				top1: '4-2',
				top2: ['4'],
				titles: ['编号', '商品货号', '商品图片', '商品名称','所属店铺', '商品价格', '是否上架', '商品库存','销量', '审核状态', '操作'],
				flag: 1,
				currentPage4: 1,
				titlePage:1,
				currentpage: 1,
				page:1,
				selectshop:'',
				select:'',
				set:1,
				pageSize:15,
				datas: null,
				tabbar: ['全部商品', '已上架', '未上架', '待审核', '未通过'],
				num: 100
			}
		},
		components: {
			frame,
			lists,
			tabbar,
			search,
			page
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.goodsQueryList({
				token,
				gname: '',
				storeid: this.select,
				pstoreid:this.selectshop,
				page: 1,
				limit: this.pageSize,
				status: this.set
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data.data
					this.titlePage = res.data.count
				}
			})
		},
		methods: {
			handleSizeChange(val) {
			  console.log(`每页 ${val} 条`);
			  
			   this.pageSize = val;
			let token = this.$storage.getLocal('token');
			this.$request.goodsQueryList({
				token,
				gname: '',
				storeid: this.select,
				pstoreid:this.selectshop,
				page: 1,
				limit: this.pageSize,
				status: this.set
			}).then(res => {
				console.log(res)
				if (res.code == 0) {
					this.datas = res.data.data
					// this.titlePage = res.data.cunt
				}
			})
			},
			
			handleCurrentChange(val) {
		this.page=val
		let token = this.$storage.getLocal('token');
		this.$request.goodsQueryList({
			token,
			gname: '',
			storeid: this.select,
			pstoreid:this.selectshop,
			page: val,
			limit: this.pageSize,
			status: this.set
		}).then(res => {
			if (res.code == 0) {
				this.datas = res.data.data
				// this.num = res.data.cunt
			}
		})
			    },
			refresh() {
				let token = this.$storage.getLocal('token');
				this.$request.goodsQueryList({
					token,
					gname: '',
					storeid: this.select,
					pstoreid:this.selectshop,
					page: this.page,
					limit: this.pageSize,
					status: this.set
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.titlePage = res.data.count
					}
				})
			},
			cut(item) {
				let set = 1
				if (item == '全部商品') {
					set = 1
				} else if (item == '已上架') {
					set = 2
				} else if (item == '未上架') {
					set = 3
				} else if (item == '待审核') {
					set = 4
				} else {
					set = 5
				}
				this.set=set
				let token = this.$storage.getLocal('token');
				this.$request.goodsQueryList({
					token,
					gname: '',
					storeid: this.select,
					pstoreid:this.selectshop,
					page: this.page,
					limit: this.pageSize,
					status: set
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.titlePage = res.data.count
					}
				})
			},
			search(item) {
				console.log(item)
				this.select=item.select
				this.selectshop=item.selectshop
				let token = this.$storage.getLocal('token');
				this.$request.goodsQueryList({
					token,
					gname: item.text,
					storeid: item.select,
					pstoreid:item.selectshop,
					page: this.page,
					limit: this.pageSize,
					status: this.set
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.titlePage = res.data.count
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.commodityList
	{
	background-color: rgb(245,247,249);
	height: 1000px;
	}
</style>
