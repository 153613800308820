<template>
	<div class="content">

		<div class="search">
			<div class="input">
				<div class='text'>请输入：</div>
				<Input v-model="value14" placeholder="请输入商品名称" clearable style="width: 200px" />
			</div>
			<div class="input" v-if="admin_type==1||admin_type==2">
				<div class='text'></div>
				<Form :model="formItem" :label-width="120" v-if="admin_type==1">
					<FormItem label="商户名称:">
						<Select v-model="formItem.selectshop">
							<Option :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shipOpt(item.storeid)">{{item.s_name}}</Option>
							<!-- <Option value="shanghai">London</Option> -->
							<!-- <Option value="shenzhen">Sydney</Option> -->
						</Select>
					</FormItem>
				</Form>
				
				<Form :model="formItem" :label-width="120">
					<FormItem label="店铺名称:">
						<Select v-model="formItem.select">
							<Option :value="item.storeid" v-for='item,index in selectst' :key='index'>{{item.s_name}}</Option>
							<!-- <Option value="shanghai">London</Option> -->
							<!-- <Option value="shenzhen">Sydney</Option> -->
						</Select>
					</FormItem>
				</Form>
			</div>
			<div class="input">
				<Button type="error" @click='search()'>搜索</Button>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'search',
		props: {
			set: {
				type: Number,
			},
			pageSize: {
				type: Number,
			},
		},
		data() {
			return {
				value14: '',
				formItem: {
					select: '',
					selectshop:''
				},
				selects: null,
				selectst:null,
				admin_type:''
			}
		},
		created() {
			console.log(this.$props)
			let token = this.$storage.getLocal('token');
			var admin_type=this.$storage.getLocal('type')
			this.admin_type=admin_type
			if(admin_type=='1')
			{
				this.$request.selGoodsStoreName({token,type:1}).then(res=>{
					if(res.code == 0){ 
						console.log(res)
						this.selects = res.data;
					}
				})
			}else if(admin_type=='2')
			{
				this.$request.selGoodsStoreName({token,type:2}).then(res=>{
					if(res.code == 0){ 
						this.selectst = res.data;
					}
				})
			}
	
		},
		methods: {
			shipOpt(value)
			{
				let token = this.$storage.getLocal('token');
				this.$request.selGoodsStoreName({token,type:2,pstoreid:value}).then(res=>{
					if(res.code == 0){ 
						console.log(res)
						this.selectst = res.data;
					}
				})
			},
			search(){
				console.log(this.value14)
				console.log('666',this.formItem.select)
				this.$emit('search',{text:this.value14,select:this.formItem.select,selectshop:this.formItem.selectshop})
			}
		}
	}
</script>

<style scoped="scoped">
	.content
	{
		/* width: 60%; */
		}
	.search {
		padding-left: 200px;
		display: flex;
		/* justify-content: center; */
		text-align: left;
	}

	.input {
		/* width: 80%; */
		/* margin: 0 auto; */
		display: flex;
		justify-content: space-between;
		line-height: 30px;
		margin-left: 50px;
		padding: 10px 0 10px 0;
	}

	.liubai {
		width: 100%;
		height: 40px;
		background-color: #F3F3F3;
		font-size: 15px;
		text-align: left;
		padding-left: 220px;
		line-height: 40px;
	}
</style>
